<template>
  <!-- 接口管理页面 -->
  <div class="Interface">
    <!-- 筛选区域 -->
    <div class="screening">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="筛选框" name="1">
          <div class="screening-out">
            <el-form
              :inline="true"
              :model="searchForm"
              class="demo-form-inline"
            >
              <!-- 筛选项 -->
              <el-row>
                <el-col :span="24">
                  <el-row :gutter="12">
                    <el-col :span="6">
                      <el-form-item label="接口状态：" prop="">
                        <el-select
                          v-model="searchForm.status"
                          placeholder="请选择"
                          style="width: 100%"
                          clearable
                          size="mini"
                        >
                          <el-option
                            v-for="(item, index) in ['正常', '禁用']"
                            :key="index"
                            :label="item"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="创建日期：">
                        <el-date-picker
                          v-model="valueTimeCreate"
                          type="datetimerange"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          style="width: 100%"
                          value-format="YYYY-MM-DD HH:mm:ss"
                          :default-time="defaultTime"
                          size="mini"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <div class="title-buttom">
                            <el-button class="cancel" size="small" @click="searchTapReset" round>重置</el-button>
                            <el-button type="primary" size="small" class="confirmAdd" @click="searchTapFun" round>查询</el-button>
                        </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">数据列表</div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <el-tooltip content="新增账户" placement="top" effect="light">
            <el-button
              class="editor"
              size="mini"
              icon="el-icon-plus"
              @click="addOrModify(0)"
              circle
            ></el-button>
          </el-tooltip>
          <!-- <el-tooltip content="列表刷新" placement="top" effect="light">
            <el-button
              class="editor"
              size="mini"
              icon="el-icon-refresh-right"
              @click="searchTapReset()"
              circle
            ></el-button>
          </el-tooltip> -->
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 数据列表 -->
    <el-row>
      <el-col>
        <div>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
          >
            <!-- 公共下拉选择 -->
            <el-table-column type="expand">
              <template #default="scope">
                <div v-if="evalJSON(scope.row)">
                  <a-descriptions bordered :column="1" size="small">
                    <template v-for="(value, key) in evalJSON(scope.row)">
                      <a-descriptions-item :label="key" >
                        {{ value }}
                      </a-descriptions-item>
                    </template>
                  </a-descriptions>
                </div>
                <div v-else>
                  该接口无配置信息
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="通信账户">
              <template #default="scope">{{
                scope.row.name == null ? "-" : scope.row.name
              }}</template>
            </el-table-column> -->
            <el-table-column label="所属接口">
              <template #default="scope">{{
                scope.row.interfaceId == null ? "-" : mateInterface(scope.row.interfaceId)
              }}</template>
            </el-table-column>
            <el-table-column label="添加时间" width="200">
              <template #default="scope">{{
                scope.row.createTime == null ? "-" : scope.row.createTime
              }}</template>
            </el-table-column>
            <el-table-column label="账户状态">
              <template #default="scope">{{
                scope.row.status == null ? "-" : scope.row.statusText
              }}</template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-tooltip content="编辑菜单" placement="top" effect="light">
                    <el-button class="editor" size="mini" icon="el-icon-edit" @click="modify(scope.row.id)" round></el-button>
                </el-tooltip>
                <el-tooltip content="删除菜单" placement="top" effect="light">
                    <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="confirmEvent(scope.row.id)" round></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <el-row>
      <el-col :span="16"></el-col>
      <el-col :span="8">
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            background
            :page-sizes="[10, 20, 30, 40]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <!-- 添加修改 -->
    <div>
        <el-form label-position="top" label-width="100px" :model="formMenu" :rules="rules" ref="formMenu">
            <el-dialog
                v-model="centerDialogVisible"
                :title="popupTitle == 0 ? '新增账户' : '修改账户'"
                width="30%"
                :destroy-on-close="true">
                <div>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="账户名称：" prop="name">
                                <el-input v-model="formMenu.name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="所属接口：" prop="interfaceId">
                                <el-select v-model="interfaceValue" placeholder="请选择">
                                    <el-option
                                    v-for="item in interface_id"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="参数设置：" prop="options">
                                <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                 :autosize="{ minRows: 4, maxRows: 6}"
                                v-model="formMenu.options"
                                show-word-limit
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <template #footer>
                <span class="dialog-footer">
                    <el-form-item>
                        <el-button class="cancel" plain @click="resetForm('formMenu')" round>取消</el-button>
                        <el-button class="confirmAdd" @click="submitForm('formMenu')" round>{{ popupTitle == 0 ? '新增账户' : '修改账户' }}</el-button>
                    </el-form-item>
                </span>
                </template>
            </el-dialog>
        </el-form>
    </div>
  </div>
</template>
<script>
import amount from "@/api/open/amount/amount";
import interfacets from '@/api/open/privacy/interface';
import { IconFont } from "@/utils/iconfont";
export default {
  name: "Interface",
  components: {
    IconFont
  },
  data() {
    return {
        tableData: [], //当前页面数据
        activeNames: [], //折叠面板
        loading: true, // loding 状态启用
        valueTimeCreate: [], //选中时间段--创建时间
        defaultTime: [
            new Date(2000, 1, 1, 12, 0, 0),
            new Date(2000, 2, 1, 8, 0, 0),
        ], //默认时间 '12:00:00', '08:00:00'
        currentPage: 1, // 分页
        total: 0, //总数据条数
        searchForm: {
            // 搜索项
            page: 1,
            pageSize: 10,
            createTimeBegin: "", // 创建时间--开始
            createTimeEnd: "", // 创建时间--结束
            status: "", // 接口状态(0:正常 1:禁用)
        },
        interface_id:[],
        interface_list:{},
        // 弹窗
        popupTitle:0,
        centerDialogVisible:false,
        interfaceValue:'',
        formMenu: {
            name: '',
            interfaceId:'',
            options:''
        },
        rules: {
            name: [
                { required: true, message: '请输入账户名称', trigger: 'blur' },
                { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' }
            ],
            interfaceId: [
                { required: true, message: '请选择所属接口', trigger: 'blur' },
            ],
            options: [
                { required: true, message: '请填参数设置', trigger: 'blur' },
            ],
        },
    };
  },
  methods: {
    // 添加或修改
    addOrModify(type) {
    this.formMenu = {
        name: '',
        interfaceId:'',
        options:''
    }
    this.popupTitle = type
    this.centerDialogVisible = true
    },
    // 重置搜索项
    searchTapReset() {
      this.searchForm = {
        page: this.currentPage,
        pageSize: 10,
      };
      this.valueTimeCreate = [];
      this.getDataList();
    },
    // 搜索特殊项
    searchTapFun() {
      if (this.valueTimeCreate) {
        this.searchForm.createTimeBegin = this.valueTimeCreate[0];
        this.searchForm.createTimeEnd = this.valueTimeCreate[1];
      } else {
        this.searchForm.createTimeBegin = "";
        this.searchForm.createTimeEnd = "";
      }
      this.getDataList();
    },
    // JSON转换
    evalJSON(item) {
      if (item.options) {
        if(typeof JSON.parse(item.options) == 'object'){
            return JSON.parse(item.options);
        }else{
            return { '标题':'参数设置格式不匹配' }
        }
      }
    },
    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.getDataList();
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.currentPage = val;
      this.getDataList();
    },
    // 获取数据列表
    getDataList() {
      this.loading = true;
      amount.amountList({ ...this.searchForm }).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data;
        let newJson = {}
        res.data.forEach(item => {
            newJson[item.id] = item.name
        });
        this.interface_list = newJson
      });
    },
    mateInterface(item){
        return this.interface_list[item]
    },
    modify(id){
        amount.amountGet({ 
            id:id
         }).then((res) => {
             this.interfaceValue = res.data.interfaceId
             this.formMenu = res.data
            this.popupTitle = 1
            this.centerDialogVisible = true
        })
    },
     // 删除确认
    confirmEvent(id) {
        this.$confirm('将删除该通信账户, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            amount.amountDelete({
                id:id
            }).then((res) => {
                this.getDataList();
                this.$notify({
                    title: '成功',
                    message: '删除成功',
                    type: 'success'
                });
            })
        }).catch(() => {
            this.$notify.info({
                title: '通知',
                message: '已取消删除!'
            });
        });
    },
    // 表单提交
    submitForm(formName) {
        this.formMenu.interfaceId = this.interfaceValue
        this.$refs[formName].validate((valid) => {
        if (valid) {
            if(this.popupTitle == 0){
                amount.amountAdd({ ...this.formMenu }).then((res) => {
                    this.getDataList();
                    this.$notify({
                        title: '成功',
                        message: '新增成功',
                        type: 'success'
                    });
                    this.centerDialogVisible = false
                })
            }
            if(this.popupTitle == 1){
                amount.amountEdit({ ...this.formMenu }).then((res) => {
                    this.getDataList();
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.centerDialogVisible = false
                })
            }
        } else {
            // console.log('error submit!!');
            return false;
        }
        });
    },
    resetForm(formName) {
        this.$refs[formName].resetFields();
        this.centerDialogVisible = false
    },
  },
  mounted() {
    this.getDataList();
    this.getApiType()
  },
};
</script>
<style lang="scss" scoped>
.Interface {
  height: calc(100vh - 135px);
  overflow: overlay;
  padding-right: 1.1rem;
  box-sizing: content-box;
  .title-name {
    margin-bottom: 1rem;
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .title-button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    // background-color:orange;
  }
  .screening {
    margin-bottom: 0.9375rem;
    .screening-out {
      margin: 0 0.9375rem;
    }
    .title-button {
      padding-right: 0;
    }
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.editor{
    color: #0170ED;
    background: #E3F0FF;
}
.deletetable{
    color: #FF845B;
    background: #FFE4DB;
}
</style>